import { API_ENV, environment } from "./environment";

const SetApiBaseUrl = (): string => {

  const result = "";
  switch (API_ENV) {

    case environment.local:
      return `https://dv-fa-oracle-api.azurewebsites.net/v1`;

    case environment.qa:
      return `https://dv-fa-oracle-api.azurewebsites.net/v1` //'https://dv-oracle-api-demo.azurewebsites.net/v1'//`https://dv-fa-oracle-api.azurewebsites.net/v1`;

    case environment.dev:
      return `https://dv-fa-oracle-api.azurewebsites.net/v1` //'https://dv-oracle-api-demo.azurewebsites.net/v1'//`https://dv-fa-oracle-api.azurewebsites.net/v1`;

    case environment.production:
      return `https://dv-fa-oracle-api.azurewebsites.net/v1` //'https://dv-oracle-api-demo.azurewebsites.net/v1'//`https://dv-fa-oracle-api.azurewebsites.net/v1`;
  }

  return result;
}


export const API_BASE_URL = SetApiBaseUrl();

const SetSqlApiBaseUrl = (): string => {

  const result = "";
  switch (API_ENV) {

    case environment.local:
      return `https://dv-fa-oracle-api.azurewebsites.net/v1`//environment.APIS.Oracle.URL; ;

    case environment.qa:
      return 'https://dv-fa-oracle-api.azurewebsites.net/v1' //environment.APIS.Oracle.URL;//`https://dv-fa-oracle-api.azurewebsites.net/v1`;

    case environment.dev:
      return 'https://dv-fa-oracle-api.azurewebsites.net/v1' //environment.APIS.Oracle.URL; //'https://dv-oracle-api-demo.azurewebsites.net/v1'//`https://dv-fa-oracle-api.azurewebsites.net/v1`;

    case environment.production:
      return 'https://dv-fa-oracle-api.azurewebsites.net/v1' //environment.APIS.Oracle.URL; //'https://dv-oracle-api-demo.azurewebsites.net/v1'//`https://dv-fa-oracle-api.azurewebsites.net/v1`;
  }

  return result;
}


export const API_SQL_BASE_URL = SetSqlApiBaseUrl();
