import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileStoreService {

  private GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

  public profile: ProfileType;
  public hasEmployeeId = new BehaviorSubject<boolean>(false);

  public get employeeId() {
    if (this.profile?.employeeId) {
      return this.profile?.employeeId;
    }

    this.getProfile();

    return this.profile?.employeeId;
  }

  constructor(private http: HttpClient) {

    this.getProfile();
  }

  getProfile() {
      this.http.get(`${this.GRAPH_ENDPOINT}?$select=displayName,givenName,surname,employeeId,email,postalCode,mail,id`)
        .subscribe(profile => {
          this.profile = profile;
          console.log('profile', this.profile);
          this.hasEmployeeId.next(true);
        });
    }
}



type ProfileType = {
  givenName?: string,
  surname?: string,
  mail?: string,
  id?: string,
  employeeId?: number,
};
