// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: 'local',
  qa: 'qa',
  dev: 'dev',
  local: 'local',
  APIS: {
    Oracle: {
      URL: 'http://localhost:7071/v1',//'https://dv-fa-oracle-api.azurewebsites.net/v1', //https://api.oracle.davaco.com'
      API_KEY: 'GBCIBa7zXbmKfO8pRDEy2dmX2JQghcXqgmX1YHQFAEkKWqBOkeylbg==', // 'pr4QqDhkdnPo1Gb4Jdm4IVWsjd7Mdi3btZDdhzSwL0mQZN7DVnayGw==',

  }
}
};


export const API_ENV = environment.dev;


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
