import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  {
    path: 'home',
    canActivate: [MsalGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'profile',
    canActivate: [MsalGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'help',
    canActivate: [MsalGuard],
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'mobile-support',
    canActivate: [MsalGuard],
    loadChildren: () => import('./mobile-support/mobile-support.module').then(m => m.MobileSupportPageModule)
  },
  {
    path: 'help-desk',
    canActivate: [MsalGuard],
    loadChildren: () => import('./help-desk/help-desk.module').then(m => m.HelpDeskPageModule)
  },
  {
    path: 'supported-devices',
    canActivate: [MsalGuard],
    loadChildren: () => import('./supported-devices/supported-devices.module').then(m => m.SupportedDevicesPageModule)
  },
  {
    path: 'transaction',
    canActivate: [MsalGuard],
    loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionPageModule)
  },
  {
    path: 'transaction-project',
    loadChildren: () => import('./transaction-project/transaction-project.module').then( m => m.TransactionProjectPageModule)
  },
  {
    path: 'transaction-distribution/:id',
    loadChildren: () => import('./transaction-distribution/transaction-distribution.module').then( m => m.TransactionDistributionPageModule)
  },
  {
    path: 'transaction-receipts',
    loadChildren: () => import('./transaction-receipts/transaction-receipts.module').then( m => m.TransactionReceiptsPageModule)
  },
  {
    path: '**',
    redirectTo: '/transaction',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
