import { API_BASE_URL, API_SQL_BASE_URL } from "./apiUrl"

export const API_URLS = {
  customer: {
    getCustomers: `${API_BASE_URL}/oracle/customer`,
    customerLocations: (custId: number) => `${API_BASE_URL}/oracle/customer/${custId}/locations`,
    customerProjects: (customerId: number) => `${API_BASE_URL}/customer/${customerId}/projects`,
  },
  project: {
    getProject: `${API_BASE_URL}/projects`,
    getProjectCategory: (projectNo) => `${API_BASE_URL}/project/${projectNo}/category-types`,
    getProjectExpenditure: (projectNo) => `${API_BASE_URL}/project/${projectNo}/expenditure-categories`,
    getProjectLocations: (projectId: number) => `${API_BASE_URL}/project/${projectId}/locations`,
    postProjectExpense: `${API_SQL_BASE_URL}/transaction`,
  },
  expense: {
    // creditCardTransaction: (empId) => `${API_SQL_BASE_URL}/employee/${empId}/transactions`,
    creditCardTransaction: (empId) => `${API_BASE_URL}/expense/credit-card-trans/${empId}`,
    creditCardTransactionMSAL: `${API_BASE_URL}/employee`,
    expenditureCategory: `${API_BASE_URL}/expenditure-category`,
  },
  user: {
    getUserByUserId: `${API_BASE_URL}/oracle/user/{employeeId}`
  },
  utilities: {
    getStates: `${API_BASE_URL}/oracle/state-province`,
    locations: `${API_BASE_URL}/location`,
  },
};
