import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { API_URLS } from 'src/environments/apiUrls';
import { APP_BASE_CONFIG } from 'src/environments/AppBaseConfig';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptorService } from './services/http-request-interceptor.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: '91f0535e-df2f-4459-9e42-4861a5ba8bf8', // This is your client ID
        authority: 'https://login.microsoftonline.com/20266eb8-0c6e-4253-bbdb-e7db3a9dc961', // This is your tenant ID
        redirectUri: 'https://dev.expense.clearthread.com', //// `${APP_BASE_CONFIG.BASE_APP}``http://localhost:4200`This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [`${API_URLS.expense.creditCardTransactionMSAL}`, ['user.read']],
        [`${API_URLS.customer.getCustomers}`, ['user.read']],
        [`${API_URLS.project.getProject}`, ['user.read']]
      ])
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
